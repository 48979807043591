@import 'styles/theme';

.BlogGrid {
  padding-block: theme($spacings, 60);
  row-gap: theme($spacings, 60);

  @include from('md') {
    row-gap: theme($spacings, 100);
  }

  .item {
    grid-column: 1 / -1;
    height: fit-content;

    @include from('md') {
      grid-column: span 3;
    }
  }
}
