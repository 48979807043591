@import 'styles/theme';

.MediaGrid {
  margin-top: theme($spacings, 88);
  margin-bottom: theme($spacings, 88);
  grid-row-gap: 36px;
  grid-template-rows: repeat(1, 1fr);

  @include from('md') {
    grid-row-gap: theme($desktop, 'gap');
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.layout-1 {
    @include from('md') {
      .mediaContainer:nth-child(1) {
        grid-area: 1 / 1 / 3 / 4;
      }
      .mediaContainer:nth-child(2) {
        grid-area: 1 / 4 / 3 / 7;
      }
      .mediaContainer:nth-child(3) {
        grid-area: 3 / 1 / 5 / 4;
      }
      .mediaContainer:nth-child(4) {
        grid-area: 3 / 4 / 5 / 7;
      }
      .mediaContainer:nth-child(5) {
        grid-area: 1 / 7 / 5 / 13;
      }
    }
  }

  &.layout-2 {
    @include from('md') {
      .mediaContainer:nth-child(1) {
        grid-area: 1 / 1 / 5 / 7;
      }
      .mediaContainer:nth-child(2) {
        grid-area: 1 / 7 / 3 / 10;
      }
      .mediaContainer:nth-child(3) {
        grid-area: 1 / 10 / 3 / 13;
      }
      .mediaContainer:nth-child(4) {
        grid-area: 3 / 7 / 5 / 10;
      }
      .mediaContainer:nth-child(5) {
        grid-area: 3 / 10 / 5 / 13;
      }
    }
  }

  .mediaContainer {
    grid-column: 1 / -1;
    position: relative;
    border-radius: theme($borderRadius, 'default');
    overflow: hidden;
  }

  .media {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .video {
    border-radius: theme($borderRadius, 'default');
  }
}
