@import 'styles/theme';

.EditorialImagesColumns {
  margin-top: theme($spacings, 88);
  margin-bottom: theme($spacings, 88);
  .imageContainer {
    border-radius: theme($borderRadius, 'default');
    overflow: hidden;
    grid-column: span 12;

    + .imageContainer {
      margin-top: theme($spacings, 32);

      @include from('md') {
        margin-top: 0;
      }
    }
  }

  &.small {
    .imageContainer {
      @include from('md') {
        grid-column: span 4;
      }
    }
  }

  &.large {
    .imageContainer {
      @include from('md') {
        grid-column: span 6;
      }
    }
  }
}
