@import 'styles/theme';

.VideoPlayerMock {
  position: relative;
  cursor: pointer;

  @include set-transition(
    (
      'opacity': 'default',
    )
  );

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
