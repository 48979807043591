@import 'styles/theme';

.ColumnContact {
  display: flex;
  flex-direction: column;

  @include from('md') {
    padding-right: theme($spacings, 24);
    padding-left: theme($spacings, 24);
  }

  .title,
  .description {
    margin-bottom: theme($spacings, 24);
  }

  .title {
    text-align: center;
  }

  .description {
    line-height: 1.8;

    @include from('md') {
      text-align: center;
    }
  }

  .cta {
    margin-top: auto;

    .label {
      display: flex;
      align-items: center;
    }

    .icon {
      margin-right: theme($spacings, 8);
    }
  }
}
