@import 'styles/theme';

.EditorialTwoColumnsContact {
  margin-top: theme($spacings, 88);
  margin-bottom: theme($spacings, 88);

  .item {
    grid-column: 1/-1;

    & + .item {
      padding-top: theme($spacings, 40);
      margin-top: theme($spacings, 40);
      border-top: 1px solid rgba(theme($colors, 'shade-1'), 0.2);
    }

    @include from('md') {
      &:first-child {
        grid-column: 3/7;
      }
      &:last-child {
        grid-column: 7/11;
      }

      & + .item {
        padding-top: 0;
        margin-top: 0;
        border: none;
      }
    }
  }
}
