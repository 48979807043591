@import 'styles/theme';

.EditorialText {
  margin-top: theme($spacings, 88);
  margin-bottom: theme($spacings, 88);

  &.hasTopSeparator {
    margin-top: 0;
    padding-top: theme($spacings, 88);
    border-top: 1px solid rgba(theme($colors, 'shade-1'), 0.2);
  }

  &.hasBottomSeparator {
    margin-bottom: 0;
    padding-bottom: theme($spacings, 88);
    border-bottom: 1px solid rgba(theme($colors, 'shade-1'), 0.2);
  }

  .container {
    grid-column: 1/-1;

    @include from('md') {
      grid-column: 4 / -4;
    }
  }

  .ctaContainer {
    margin-top: theme($spacings, 16);
    margin-bottom: theme($spacings, 16);
    grid-column: 1/-1;
    display: flex;
    justify-content: center;

    @include from('md') {
      grid-column: 4 / -4;
    }
  }

  &.isCentered {
    text-align: center;
  }
}
