@import 'styles/theme';

.EditorialVideo {
  position: relative;
  margin-top: theme($spacings, 88);
  margin-bottom: theme($spacings, 88);

  .videoPlayer,
  .videoPlayerMock {
    grid-column: 1/-1;
    border-radius: theme($borderRadius, 'default');
    overflow: hidden;

    @include from('md') {
      height: calc(stableVh(100) - theme($navHeight, 'desktop'));
      grid-column: 2 / -2;
    }
  }

  .videoPlayerMock {
    z-index: 3;
    position: relative;
  }

  .playPauseLayer {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .pauseButton {
      opacity: 0;

      @include set-transition(
        (
          'opacity': 'default',
        )
      );
    }

    @include from('md') {
      &:hover {
        .pauseButton {
          opacity: 1;
        }
      }
    }
  }

  .videoPlayer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .videoControls {
    z-index: 2;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0 theme($spacings, 16) theme($spacings, 16) theme($spacings, 16);
    box-sizing: border-box;

    @include from('md') {
      padding: 0 theme($spacings, 32) theme($spacings, 32) theme($spacings, 32);
    }
  }

  .playPauseButton,
  .muteUnmuteButton {
    border-radius: theme($borderRadius, 'default');
    height: 40px;
    width: 40px;
    will-change: background, backdrop-filter;

    @include set-transition(
      (
        'all': 'default',
      )
    );

    @include from('md') {
      &:hover {
        @include background-opacity(theme($colors, 'black'), 0.05);
        backdrop-filter: blur(25px);
      }
    }
  }

  .progressBar {
    margin-left: theme($spacings, 16);
    margin-right: theme($spacings, 16);
  }

  .videoContainer {
    pointer-events: none;
    z-index: 4;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;

    @include set-transition(
      (
        'opacity': 'default',
      )
    );

    @include applyColumns(
      (
        'propertyName': 'padding',
        'desktop': 1,
        'mobile': 1,
      )
    );

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  .playButton,
  .pauseButton {
    @include background-opacity(theme($colors, 'black'), 0.05);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    backdrop-filter: blur(25px);
    border-radius: theme($borderRadius, 'default');
  }

  .playRoundedIcon {
    width: 40px;
    height: 40px;
  }

  // States
  &.isFullPage {
    .videoPlayer,
    .videoPlayerMock {
      border-radius: 0;
    }
  }

  &.withBanner {
    .videoPlayer,
    .videoPlayerMock {
      @include from('md') {
        height: calc(
          stableVh(100) - theme($navHeight, 'desktop') -
            theme($navHeight, 'desktopBanner')
        );
      }
    }
  }
}
