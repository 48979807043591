@import 'styles/theme';

.PushSliderDesktop {
  padding-block: theme($spacings, 60);
  @include switchDesktopMobileClasses('md', grid);

  .header {
    grid-column: 1 / -1;
    justify-content: center;
    margin-bottom: theme($spacings, 50);

    @include from('md') {
      margin-bottom: theme($spacings, 60);
      justify-content: space-between;
    }

    .title {
      grid-column: 1 / 6;
      margin-bottom: theme($spacings, 60);
    }

    .cta {
      place-self: flex-end;
      grid-column: 6 / -1;
      margin-bottom: theme($spacings, 60);
    }
  }

  .sliderContainer {
    grid-column: 4/ 13;
    position: relative;
    overflow: hidden;
  }

  .slider {
    width: 100%;
    box-sizing: border-box;

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'margin-left',
        'gutters': 0,
        'gaps': 1,
        'columns': 0,
      )
    );

    @include from('md') {
      margin-left: 0;
    }
  }

  .media {
    grid-column: 1/ 4;

    .mediaItem {
      position: absolute;
    }
  }

  .sliderItem {
    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'width',
        'gutters': 0,
        'gaps': 0,
        'columns': 6,
      )
    );

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'margin-left',
        'gutters': 0,
        'gaps': 1,
        'columns': 0,
      )
    );

    @include from('md') {
      margin-left: 0;

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'width',
          'gutters': 0,
          'gaps': 0,
          'columns': 3,
        )
      );

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'margin-left',
          'gutters': 1,
          'gaps': 0,
          'columns': 0,
        )
      );
    }
  }

  .sliderControls {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: theme($spacings, 32);
  }
}

.PushSliderMobile {
  margin-block: theme($spacings, 60);
  @include switchDesktopMobileClasses('md', grid);

  .header {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    margin-bottom: theme($spacings, 50);
  }

  .image {
    grid-column: 1 / -1;
  }

  .content {
    grid-column: 1 / -1;
    margin-top: theme($spacings, 15);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: theme($spacings, 15);
  }

  .footer {
    grid-column: 1 / -1;
    display: flex;
    margin-top: theme($spacings, 50);
    justify-content: center;
  }
}
