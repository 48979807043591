@import 'styles/theme';

.HorizontalMedia {
  padding-block: theme($spacings, 50);
  overflow: hidden;

  @include switchDesktopMobileClasses('md');

  .image {
    margin-bottom: theme($spacings, 10);
  }

  .sliderContainer {
    position: relative;
    overflow: hidden;
  }

  .slider {
    width: 100%;
    box-sizing: border-box;

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'margin-left',
        'gutters': 0,
        'gaps': 1,
        'columns': 0,
      )
    );

    @include from('md') {
      margin-left: 0;
    }
  }

  .sliderItem {
    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'width',
        'gutters': 0,
        'gaps': 0,
        'columns': 5.5,
      )
    );

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'margin-left',
        'gutters': 0,
        'gaps': 1,
        'columns': 0,
      )
    );

    @include from('md') {
      margin-left: 0;

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'width',
          'gutters': 0,
          'gaps': 0,
          'columns': 3,
        )
      );

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'margin-left',
          'gutters': 1,
          'gaps': 0,
          'columns': 0,
        )
      );
    }
  }

  .sliderControls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: theme($spacings, 32);
  }
}
