.pageContent {
  &.withoutMarginTop {
    > * {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  &.withoutMarginBottom {
    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
