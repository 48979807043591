@import 'styles/theme';

.EditorialAccordions {
  margin-top: theme($spacings, 88);
  margin-bottom: theme($spacings, 88);

  .title {
    grid-row: 1;
    grid-column: 1/-1;
    text-align: center;
    margin-bottom: theme($spacings, 56);
  }

  .accordionGroups {
    margin: 0 auto;

    @include applyColumns(
      (
        'propertyName': 'width',
        'desktop': 6,
        'mobile': 6,
      )
    );

    .accordionGroupItem {
      margin-bottom: theme($spacings, 56);
      overflow: unset;
    }

    .accordionGroupTitle {
      text-align: center;
      margin-bottom: theme($spacings, 16);
    }
  }
}
