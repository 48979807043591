@import 'styles/theme';

$imgSize: 160px;

.EditorialColoredBlock {
  @include below('md') {
    padding-left: 0;
    padding-right: 0;
  }
  padding-top: calc($imgSize / 2);
  margin-top: theme($spacings, 88);
  margin-bottom: theme($spacings, 88);

  .container {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: 1 / -1;
    border-radius: theme($borderRadius, 'default');
    padding-left: 32px;
    padding-right: 32px;

    @include from('md') {
      padding-left: 64px;
      padding-right: 64px;
      grid-column: 1 / -1;
    }

    @include from('lg') {
      grid-column: 3 / -3;
    }

    @include from('l') {
      grid-column: 4 / -4;
    }
  }

  .imageContainer {
    border-radius: 50%;
    width: $imgSize;
    margin-top: calc($imgSize * -0.5);
    overflow: hidden;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title,
  .subtitle,
  .paragraph {
    text-align: center;

    + .cta {
      margin-top: theme($spacings, 32);
      @include from('md') {
        margin-top: theme($spacings, 24);
      }
    }
  }

  .title,
  .subtitle {
    + .paragraph {
      margin-top: theme($spacings, 16);
    }
  }

  .imageContainer + .content {
    margin-top: theme($spacings, 24);
  }

  .title + .subtitle {
    margin-top: theme($spacings, 4);
  }

  .paragraph {
    @include from('lg') {
      @include applyColumnWidth('width', 5, $desktop);
    }
  }

  .cta {
    margin-bottom: theme($spacings, 48);
  }
}
