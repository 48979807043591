@import 'styles/theme';

.MagazineItem {
  position: relative;
  height: fit-content;

  .image {
    height: fit-content;
  }

  .tag {
    position: absolute;
    width: fit-content;
    right: theme($spacings, 24);
    top: theme($spacings, 24);
  }

  .content {
    margin-top: theme($spacings, 20);
    display: flex;
    flex-direction: column;
    align-items: center;

    .title + .cta,
    .subtitle + .cta {
      margin-top: theme($spacings, 10);
    }
  }

  .cta {
    text-decoration: underline;
    transition: color theme($transitions, 'default');
  }

  &:hover {
    .cta {
      color: theme($colors, 'black');
    }
  }

  &.layout {
    &-oval {
      .image {
        overflow: hidden;
        // This value can't be in pixels as the borderRadius when going over the value in px will
        // rasterize all the radiuses (top-left, top-right, bottom-left, bottom-right) to be exactly half of the container
        // So in order to avoid this and have this perfect ellipsis you have to set it to 50%
        border-radius: 50%;
      }

      .tag {
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
    }
    &-round {
      .image {
        overflow: hidden;
        border-radius: 100%;
      }

      .tag {
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
    }
    &-square {
      .image {
        overflow: hidden;
      }
    }
  }
}
