@use 'sass:math';
@import 'styles/theme';

.PushGridItem {
  position: relative;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  // min-height: 710px;

  @include switchDesktopMobileClasses('lg');

  @include from('md') {
    height: calc(stableVh(100) - theme($navHeight, 'desktop'));
    min-height: calc(700px - theme($navHeight, 'desktopBanner'));

    &.isInGrid {
      position: relative;
      border-radius: theme($borderRadius, 'default');
      min-height: 0;
      height: calc(stableVh(100) - theme($navHeight, 'desktop'));

      .wrapper {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
      }

      .container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .wrapper {
    // width: 100%;
    display: flex;
    flex: 1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
  }

  .container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: auto;
  }

  .content {
    align-self: flex-end;
    z-index: 2;
    text-align: center;
    position: relative;
    padding: theme($spacings, 32);
    background: theme($colors, 'white');
    border-radius: theme($borderRadius, 'default');
    width: 100%;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'margin',
        'gutters': 0,
        'gaps': 2,
        'columns': 0,
      )
    );

    @include from('md') {
      margin-bottom: 0;
      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'width',
          'gutters': 0,
          'gaps': 0,
          'columns': 7,
        )
      );

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'margin',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );
    }

    @include from('lg') {
      margin-bottom: 0;
      &:hover {
        box-shadow: theme($shadows, 'default');
      }

      @include set-transition(
        (
          'box-shadow': 'default',
        )
      );

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'width',
          'gutters': 0,
          'gaps': 0,
          'columns': 4,
        )
      );

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'margin',
          'gutters': 0,
          'gaps': 2.5,
          'columns': 0,
        )
      );
    }
  }

  .cta {
    margin-top: theme($spacings, 16);
  }

  &.withBanner:not(.isInGrid) {
    @include from('md') {
      height: calc(
        stableVh(100) - theme($navHeight, 'desktopBanner') -
          theme($navHeight, 'desktop')
      );
    }
  }

  &.withBanner.isInGrid {
    @include from('md') {
      min-height: calc(700px - theme($navHeight, 'desktopBanner'));
      height: calc(
        stableVh(100) - theme($navHeight, 'desktopBanner') -
          theme($navHeight, 'desktop')
      );
    }
  }
}
