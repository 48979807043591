@import 'styles/theme';

.HeroMain {
  position: relative;
  overflow: hidden;
  padding-bottom: theme($spacings, 56);
  z-index: 2;

  @include switchDesktopMobileClasses('lg');

  .slider {
    height: calc(stableVh(100) - theme($navHeight, 'mobile'));

    @include from('md') {
      height: calc(stableVh(100) - theme($navHeight, 'desktop'));
    }
  }

  &.withBanner {
    height: calc(
      stableVh(100) - theme($navHeight, 'desktopBanner') -
        theme($navHeight, 'mobile')
    );
    @include from('md') {
      height: calc(
        stableVh(100) - theme($navHeight, 'desktopBanner') -
          theme($navHeight, 'desktop')
      );
    }
  }

  .leftPanel,
  .rightPanel {
    top: 0;
    width: 50%;
    height: 100%;
    position: absolute;

    @include from('md') {
      &:hover {
        .button {
          opacity: 1;
        }
      }
    }
  }

  .leftPanel {
    left: 0;
  }
  .rightPanel {
    right: 0;
  }

  .sliderItem {
    height: calc(stableVh(100) - theme($navHeight, 'mobile'));

    &.isSingleItem {
      cursor: pointer;
    }

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'width',
        'gutters': 2,
        'gaps': 0,
        'columns': 6,
      )
    );

    @include from('lg') {
      padding-bottom: theme($spacings, 72);
      height: calc(stableVh(100) - theme($navHeight, 'desktop'));
      width: vw(100);
    }
  }

  .sliderStickers {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: auto;
    pointer-events: none;
    box-sizing: border-box;

    > div {
      overflow: hidden;
      @include below('md') {
        padding: 0;
      }
    }

    .stickersWrapper {
      position: relative;
      grid-column: 1/-1;
      display: flex;
      align-items: flex-end;

      @include setPropertyValue(
        (
          'values': $mobile,
          'propertyName': 'padding-bottom',
          'gutters': 2.5,
          'gaps': 0,
          'columns': 0,
        )
      );

      @include from('md') {
        grid-column: 3/-3;
      }

      @include from('lg') {
        grid-column: 5/-5;

        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'padding-bottom',
            'gutters': 2.5,
            'gaps': 0,
            'columns': 0,
          )
        );
      }
    }

    .sticker {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .sliderControls {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    padding-bottom: theme($spacings, 24);
    padding-top: theme($spacings, 24);

    @include from('lg') {
      padding-bottom: theme($spacings, 32);
      padding-top: theme($spacings, 32);
    }

    // Themes
    &.theme-black {
      color: theme($colors, 'black');
    }
    &.theme-white {
      color: theme($colors, 'white');
    }
  }

  .button {
    position: absolute;
    width: 50%;
    top: 0;
    bottom: 0;
    pointer-events: auto;
    opacity: 0;

    .icon {
      display: none;
      transform-origin: center center;
    }

    @include set-transition(
      (
        'opacity': 'default',
      )
    );

    @include from('md') {
      width: theme($spacings, 64);
      height: theme($spacings, 52);
      top: 50%;
      bottom: unset;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: theme($borderRadius, 'default');
      transform: translateY(-50%);

      .icon {
        display: inline-flex;
        width: 48px;
        height: 48px;
      }
    }
    &.left {
      left: 0;

      .icon {
        transform: rotate(180deg);
      }

      @include from('md') {
        left: theme($spacings, 24);
      }
    }

    &.right {
      right: 0;

      @include from('md') {
        right: theme($spacings, 24);
      }
    }
  }

  // States
  &.hasControls {
    .sliderStickers {
      padding-bottom: theme($spacings, 56);

      @include from('lg') {
        padding-bottom: theme($spacings, 72);
      }

      .stickersWrapper {
        padding-bottom: 0;

        @include from('lg') {
          padding-bottom: 0;
        }
      }
    }

    .leftPanel,
    .rightPanel {
      height: calc(100% - theme($spacings, 56));

      @include from('md') {
        height: calc(100% - theme($spacings, 72));
      }
    }
  }

  &.withBanner {
    .slider {
      height: calc(
        stableVh(100) - theme($navHeight, 'desktopBanner') -
          theme($navHeight, 'mobile')
      );
      @include from('md') {
        height: calc(
          stableVh(100) - theme($navHeight, 'desktopBanner') -
            theme($navHeight, 'desktop')
        );
      }
    }

    .sliderItem {
      height: calc(
        stableVh(100) - theme($navHeight, 'desktopBanner') -
          theme($navHeight, 'mobile')
      );

      @include from('md') {
        height: calc(
          stableVh(100) - theme($navHeight, 'desktopBanner') -
            theme($navHeight, 'desktop')
        );
      }
    }
  }
}
