@import 'styles/theme';

.HeroBanner {
  position: relative;
  overflow: hidden;
  z-index: 2;

  @include switchDesktopMobileClasses('md');

  .slider {
    display: flex;

    .simpleSlide,
    .duoSlide,
    .hybridSlide {
      position: relative;

      min-height: stableVh(100);
      width: vw(100);

      @include from('md') {
        height: 100vh;
      }

      .editoMiddle {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin',
            'gutters': 0,
            'gaps': 0,
            'columns': 1,
          )
        );

        .label {
          margin-bottom: theme($spacings, 24);
          text-align: center;
        }

        .title {
          text-align: center;
        }

        .cta {
          position: relative;
          z-index: 999;
          margin-top: theme($spacings, 60);
        }
      }

      .editoBottom {
        position: absolute;
        bottom: theme($spacings, 24);
        left: theme($spacings, 24);
      }
    }

    .simpleSlide,
    .duoSlide,
    .hybridSlide {
      display: flex;
    }

    .duoSlide {
      &.asBanner {
        box-sizing: border-box;
        min-height: 70vh;
        flex-direction: column;

        .simpleSlide {
          &:last-child {
            min-height: 50vh;
          }

          &.fullHeight {
            &:last-child {
              min-height: 100vh;
            }
          }
        }
      }

      &.desktop {
        display: none;

        @include from('md') {
          display: flex;
        }
      }

      &.mobile {
        display: flex;

        @include from('md') {
          display: none;
        }
      }
    }

    .hybridSlide {
      .hybridImagesContainer {
        display: flex;
        position: relative;
        height: 100%;
        width: 100%;
      }
    }
  }

  .sliderControls {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    margin-bottom: theme($spacings, 60);

    &.theme-black {
      color: theme($colors, 'black');
    }

    &.theme-white {
      color: theme($colors, 'white');
    }
  }
}
