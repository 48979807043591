@import 'styles/theme';

.PushProducts {
  margin-top: theme($spacings, 88);
  margin-bottom: theme($spacings, 88);
  overflow: hidden;

  @include switchDesktopMobileClasses('md', flex);

  .slides {
    position: relative;
  }

  .top {
    align-items: center;

    .title {
      text-align: center;
      grid-column: 1/-1;

      @include from('md') {
        grid-column: 2/7;
        text-align: left;
      }
    }

    .ctaWrapper {
      grid-column: 7/-2;
      margin-left: auto;
    }
  }

  .body {
    .item {
      grid-column: 1/-1;
      margin-top: theme($spacings, 32);
      margin-left: theme($spacings, 16);
      margin-right: theme($spacings, 16);

      &.isSmallItem {
        grid-column: span 3;
        margin-left: 0;
        margin-right: 0;
      }
    }

    @include from('md') {
      margin-top: theme($spacings, 56);

      .itemsWrapper {
        grid-column: 1/-1;
        max-width: 100%;
      }

      .slides {
        width: 100%;
      }

      .item {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;

        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin-right',
            'gutters': 0,
            'gaps': 1,
            'columns': 0,
          )
        );

        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'width',
            'gutters': 0,
            'gaps': 0,
            'columns': 4,
          )
        );
      }

      .sliderControls {
        display: flex;
        justify-content: center;
        margin-top: theme($spacings, 24);
        width: 100%;
      }
    }
  }

  .bottom {
    .ctaWrapper {
      margin-top: theme($spacings, 32);
      grid-column: 1/-1;
      text-align: center;
    }
  }

  // States
  &.hasNoCta {
    .top {
      .title {
        grid-column: 2/-2;
        text-align: center;
      }
    }
  }
}
