@use 'sass:math';
@import 'styles/theme';

.EditorialPushContact {
  margin-top: theme($spacings, 56);
  margin-bottom: theme($spacings, 56);

  .box {
    grid-column: 1/-1;
    @include background-opacity(theme($colors, 'shade-1'), 0.1);
    padding: theme($spacings, 32);
    border-radius: theme($borderRadius, 'default');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include from('lg') {
      justify-content: unset;
      flex-direction: row;
      grid-column: 4/-4;
    }
  }

  .left {
    text-align: center;

    @include from('lg') {
      text-align: left;
      width: math.div(360, 620) * 100%;
      padding-right: theme($spacings, 16);
    }
  }

  .title {
    margin-bottom: theme($spacings, 8);
  }

  .description {
    line-height: 1.8;
  }

  .right {
    margin-top: theme($spacings, 24);

    @include from('lg') {
      margin-left: auto;
      margin-top: 0;
    }
  }

  .ctaCopy {
    .label {
      display: inline-flex;
      align-items: center;
    }

    .icon {
      margin-left: theme($spacings, 8);
    }
  }
}
