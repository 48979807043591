@import 'styles/theme';

.HorizontalMediaItem {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  background-color: theme($colors, 'shade-3');

  .cardLink {
    width: 100%;

    .cardContainer {
      display: flex;

      .imageWrapper {
        min-width: 94px;
        margin-right: theme($spacings, 24);
        @include setPropertyValue(
          (
            'values': $mobile,
            'propertyName': 'width',
            'gutters': 0,
            'gaps': 0,
            'columns': 1,
          )
        );

        @include from('md') {
          @include setPropertyValue(
            (
              'values': $desktop,
              'propertyName': 'width',
              'gutters': 0,
              'gaps': 0,
              'columns': 0.5,
            )
          );
        }
      }

      .contentWrapper {
        margin-block: theme($spacings, 24);
        margin-right: theme($spacings, 24);
      }

      .compareAtPrice {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        color: theme($colors, 'error-compare-at-price');
      }
    }
  }
}
