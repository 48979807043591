@import 'styles/theme';

.ListWorkshops {
  margin-top: theme($spacings, 88);
  margin-bottom: theme($spacings, 88);
  overflow: hidden;

  @include switchDesktopMobileClasses('md', flex);

  .top {
    align-items: center;

    .title {
      text-align: center;
      grid-column: 1/-1;

      @include from('md') {
        grid-column: 2/7;
        text-align: left;
      }
    }

    .ctaWrapper {
      grid-column: 7/-2;
      margin-left: auto;
    }
  }

  .body {
    margin-top: theme($spacings, 32);

    .itemsWrapper {
      grid-column: 1/-1;
      overflow: hidden;
    }

    .item {
      & + .item {
        margin-top: theme($spacings, 16);
      }
    }

    @include from('md') {
      margin-top: theme($spacings, 56);

      .itemsWrapper {
        overflow: visible;
      }

      .slides {
        width: 100%;
      }

      .item {
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin-right',
            'gutters': 0,
            'gaps': 1,
            'columns': 0,
          )
        );

        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'width',
            'gutters': 0,
            'gaps': 0,
            'columns': 4,
          )
        );

        & + .item {
          margin-top: 0;
        }
      }
    }
  }

  .bottom {
    .ctaWrapper {
      margin-top: theme($spacings, 32);
      grid-column: 1/-1;
      text-align: center;
    }
  }

  // States
  &.hasNoCta {
    .top {
      .title {
        grid-column: 2/-2;
        text-align: center;
      }
    }
  }
}
