@import 'styles/theme';

.PushGrid {
  margin-top: theme($spacings, 88);
  margin-bottom: theme($spacings, 88);

  &.lastPushGridItemIsFullWidth {
    margin-bottom: 0;

    @include from('md') {
      margin-bottom: 0;
    }
  }

  &.isFirstSlice {
    &.hasOnlyOneItemAndFullWidth {
      margin-top: 0;
    }
  }

  .top {
    margin-bottom: theme($spacings, 32);

    @include from('md') {
      margin-bottom: theme($spacings, 56);
    }
  }

  .title {
    grid-column: 1/-1;
    text-align: center;
  }

  .items {
    .item {
      & + .item {
        margin-top: theme($spacings, 20);
      }
    }

    @include from('md') {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      width: 100%;
      // Sorry I had no choice
      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'margin-top',
          'gutters': 0,
          'gaps': -1,
          'columns': 0,
        )
      );

      .item {
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin-top',
            'gutters': 0,
            'gaps': 1,
            'columns': 0,
          )
        );

        &.isFull {
          width: 100%;

          & + .isFull {
            margin-top: theme($spacings, 2);
          }
        }

        & + .item {
          @include setPropertyValue(
            (
              'values': $desktop,
              'propertyName': 'margin-top',
              'gutters': 0,
              'gaps': 1,
              'columns': 0,
            )
          );
        }

        &.isHalf {
          @include setPropertyValue(
            (
              'values': $desktop,
              'propertyName': 'margin-right',
              'gutters': 0,
              'gaps': 0.5,
              'columns': 0,
            )
          );

          @include setPropertyValue(
            (
              'values': $desktop,
              'propertyName': 'margin-left',
              'gutters': 0,
              'gaps': 0.5,
              'columns': 0,
            )
          );
        }

        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'width',
            'gutters': 0,
            'gaps': 0,
            'columns': 5,
          )
        );
      }
    }
  }

  .separator {
    &:after {
      grid-column: 1/-1;
      content: '';
      height: 1px;
      width: 100%;
      @include background-opacity(theme($colors, 'shade-1'), 0.2);
    }
  }

  &.hasTopSeparator {
    margin-top: 0;

    .separator {
      margin-bottom: theme($spacings, 88);
    }
  }

  &.hasBottomSeparator {
    margin-bottom: 0;

    .separator {
      margin-top: theme($spacings, 88);
    }
  }
}
