@import 'styles/theme';

.Accordion {
  &:not(.productHeader) {
    .panel {
      border-radius: theme($borderRadius, 'default');
      @include background-opacity(theme($colors, 'shade-1'), 0.1);
      will-change: margin-top, box-shadow;
      overflow: unset;

      @include set-transition(
        (
          'margin-top': 'longer',
          'box-shadow': 'longer',
        )
      );

      &.isActive {
        box-shadow: theme($shadows, 'default');

        & + .panel {
          margin-top: theme($spacings, 56);
        }
      }

      + .panel {
        margin-top: theme($spacings, 16);

        &.isActive {
          margin-top: theme($spacings, 56);
        }
      }
    }

    .panelHead {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: theme($spacings, 16) theme($spacings, 16) theme($spacings, 16)
        theme($spacings, 24);
      width: 100%;
    }

    .headline {
      @include applyColumns(
        (
          'propertyName': 'width',
          'desktop': 5,
          'mobile': 4,
        )
      );
    }

    .icon {
      background: transparent;
      border-radius: theme($borderRadius, 'small');
      display: inline-flex;
      align-self: flex-start;

      @include set-transition(
        (
          'background': 'default',
        )
      );

      .iconItem {
        transform-origin: center center;
        transform: rotate(90deg);

        @include set-transition(
          (
            'transform': 'default',
          )
        );
      }
    }

    .panelBody {
      padding: theme($spacings, 24);
      border-top: 1px solid theme($colors, 'white');
    }

    .isActive .icon {
      @include background-opacity(theme($colors, 'shade-1'), 0.1);

      .iconItem {
        transform: rotate(270deg);
      }
    }
  }

  &.productHeader {
    .panel {
      border-top: 1px solid theme($colors, 'gray-3');
    }
    border-bottom: 1px solid theme($colors, 'gray-3');

    .panelHead {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: theme($spacings, 16) theme($spacings, 16) theme($spacings, 16)
        theme($spacings, 24);
      width: 100%;
    }

    .headline {
      width: 100%;
    }

    .icon {
      background: transparent;
      display: inline-flex;
      align-self: flex-start;

      @include set-transition(
        (
          'background': 'default',
        )
      );

      .iconItem {
        transform-origin: center center;
        transform: rotate(90deg);

        path {
          stroke: currentColor;
        }

        @include set-transition(
          (
            'transform': 'default',
          )
        );
      }
    }

    .panelBody {
      padding: 0 theme($spacings, 24) theme($spacings, 24) theme($spacings, 24);
    }

    .isActive .icon {
      .iconItem {
        transform: rotate(270deg);
      }
    }
  }
}
