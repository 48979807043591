@import 'styles/theme';

.Banner {
  width: 100%;
  .tag,
  .text {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    flex: 1;
    flex-shrink: 0;
    text-transform: uppercase;
  }
  background-color: theme($colors, 'shade-3');

  .tag {
    margin-right: 12px;
  }

  .bannerItem {
    display: flex;
    align-items: center;
    flex: 1;
    flex-shrink: 0;
    position: relative;
    padding: 22px 0;
    margin-right: 32px;
  }
}
