@use 'sass:math';
@import 'styles/theme';

.CardWorkshop {
  display: flex;
  position: relative;
  border-radius: theme($borderRadius, 'default');
  overflow: hidden;

  @include background-opacity(theme($colors, 'shade-1'), 0.1);
  @include set-transition(
    (
      'box-shadow': 'default',
    )
  );

  .left {
    width: math.div(212, 448) * 100%;
  }

  .cardImages {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .hoverImage {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100% - (math.div(212, 448) * 100%);
    box-sizing: border-box;
    padding: theme($spacings, 16);

    @include from('md') {
      padding: theme($spacings, 24);
      justify-content: unset;
    }
  }

  .content {
    padding: theme($spacings, 8);
    display: flex;
    flex-direction: column;
  }

  .title {
    margin-bottom: theme($spacings, 8);
  }

  .price {
    display: inline-flex;
    align-items: baseline;
  }

  .infos {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: theme($spacings, 16);

    @include from('md') {
      margin-top: auto;
    }
  }

  .info {
    flex-grow: 0;

    & + .info {
      margin-top: theme($spacings, 4);
    }
  }
}
