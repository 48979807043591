@import 'styles/theme';

.VideoControls {
  display: flex;
  align-items: center;

  @include set-transition(
    (
      'opacity': 'longer',
    )
  );

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  .playpausebutton,
  .muteunmutebutton {
    svg {
      width: 20px;
      max-height: 20px;
    }
  }

  .progressbar {
    flex: 1;
    padding: theme($spacings, 10) 0;
    margin-left: 10px;
  }
}
