@import 'styles/theme';

.HeroEditorial {
  text-align: center;

  margin-top: theme($spacings, 50);
  margin-bottom: theme($spacings, 32);


  @include from('md') {
    margin-top: theme($spacings, 56);
  }
  .title {
    grid-column: 1/-1;
    grid-row: 1;

    @include from('md') {
      grid-column: 2/-2;
    }
  }

  .chapo {
    grid-column: 1/-1;
    grid-row: 2;
    margin-top: theme($spacings, 16);

    @include from('md') {
      grid-column: 4/-4;
    }
  }
}
