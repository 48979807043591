@import 'styles/theme';

.ProgressBar {
  position: relative;
  width: 100%;
  cursor: pointer;

  .videoProgressContent {
    position: relative;
  }

  .videoProgressBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale3d(0, 1, 1);
    transform-origin: left center;

    @include set-transition(
      (
        'transform': 'default',
      )
    );
  }
}
