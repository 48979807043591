@import 'styles/theme';

.ProductTitleSlider {
  margin-top: theme($spacings, 88);
  margin-bottom: theme($spacings, 32);
  overflow: hidden;

  @include from('md') {
    margin-bottom: theme($spacings, 24);
  }

  .separator {
    position: relative;
    margin-bottom: theme($spacings, 88);

    &:after {
      grid-column: 1/-1;
      content: '';
      height: 1px;
      width: 100%;
    }
  }

  .title {
    grid-column: 1/-1;
    text-align: center;
  }

  .text {
    grid-column: 1/-1;
    text-align: center;

    margin-top: 3px;
  }

  .slider {
    margin-top: theme($spacings, 32);

    @include from('md') {
      margin-top: theme($spacings, 56);
    }
  }

  .contentContainer {
    grid-column: 1 / -1;
    .topRow {
      display: flex;
      justify-content: center;

      & + * {
        margin-top: theme($spacings, 24);
      }
    }

    @include from('md') {
      grid-column: 2 / -2;
    }
  }

  &.ctaHeader {
    .topRow {
      flex-direction: column;
    }

    .cta {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: theme($spacings, 20);

      @include from('md') {
        margin-top: 0;
      }

      @include switchDesktopMobileClasses('md');
    }

    .title {
      & + .cta {
        margin-top: theme($spacings, 20);
      }
    }

    @include from('md') {
      .topRow {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .title {
        width: 70%;
        max-width: 500px;

        & + .cta {
          margin-top: 0;
        }
      }
      .text {
        width: 50%;
        max-width: 600px;
      }

      .title,
      .text {
        text-align: left;
      }
    }
  }

}
