@import 'styles/theme';

.SliderItemSticker {
  align-self: flex-end;
  text-align: center;
  position: relative;
  padding: theme($spacings, 32) theme($spacings, 16);
  background: theme($colors, 'white');
  border-radius: theme($borderRadius, 'default');
  width: 100%;
  box-sizing: border-box;
  z-index: 3;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .cta {
    margin-top: theme($spacings, 12);
  }

  &.isActive {
    pointer-events: auto;
  }

  @include from('md') {
    padding: theme($spacings, 32);

    @include set-transition(
      (
        'box-shadow': 'default',
      )
    );

    &.isActive {
      &:hover {
        box-shadow: theme($shadows, 'default');
      }
    }
  }
}
