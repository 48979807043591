@import 'styles/theme';

.EditorialSliderImagesWithCaptions {
  margin-bottom: theme($spacings, 88);
  margin-top: theme($spacings, 88);
  overflow: hidden;

  .title {
    grid-column: 1/-1;
    grid-row: 1;
    text-align: center;
    margin-bottom: theme($spacings, 56);

    @include from('md') {
      grid-column: 4/-4;
    }
  }

  .sliderContainer {
    position: relative;
  }

  .slider {
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'margin-left',
        'gutters': 0,
        'gaps': 1,
        'columns': 0,
      )
    );

    @include from('md') {
      margin-left: 0;
      grid-column: 1 / -1;
    }
  }

  .sliderItem {
    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'width',
        'gutters': 0,
        'gaps': 0,
        'columns': 5.5,
      )
    );

    @include setPropertyValue(
      (
        'values': $mobile,
        'propertyName': 'margin-right',
        'gutters': 0,
        'gaps': 1,
        'columns': 0,
      )
    );

    @include from('md') {
      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'width',
          'gutters': 0,
          'gaps': 0,
          'columns': 4,
        )
      );

      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'margin-right',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );
    }
  }

  .sliderControls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: theme($spacings, 32);
  }
}
