@import 'styles/theme';

.EditorialTwoColumnsImageText {
  margin-bottom: theme($spacings, 56);
  margin-top: theme($spacings, 56);

  .content {
    align-items: center;
  }

  .image {
    grid-column: 1/-1;

    @include from('md') {
      grid-column: 3/7;
    }
  }

  .text {
    grid-column: 1/-1;
    margin-top: theme($spacings, 56);
    text-align: center;

    p {
      text-align: left;
    }

    @include from('md') {
      text-align: left;
      grid-column: 7/11;
      margin-top: 0;
      padding: 0 theme($spacings, 24);
    }
  }

  &.isReversed {
    @include from('md') {
      .text,
      .image {
        grid-row: 1;
      }
    }

    .text {
      @include from('md') {
        grid-column: 3/7;
      }
    }

    .image {
      @include from('md') {
        grid-column: 7/11;
      }
    }
  }
}
